import React, { useEffect, useRef, useState } from 'react'
import useServices from '../hooks/useServices'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faTrashAlt, faCopy, faSave, faEdit } from "@fortawesome/free-solid-svg-icons";




const TableCrud = ({columns = [],  tableName, editableRows = false}) => {
  const [dataSource, setDataSource] = useState([])
  const {getTableCrud, addTableCrud, delTableCrud, putTableCrud} = useServices();
    const formRef = useRef(null)
    
    const handleSubmit = async (e) => {
        e?.preventDefault()
    
       //console.log(formRef.current)
  
    }
    let columsTypes = {};
    columns.forEach(({dataIndex, inputType})  => columsTypes[dataIndex] = inputType )

    async function handleAdd() {
      if (!formRef.current.checkValidity()) {
 
        return;
      }
      let formData = new FormData(formRef.current)
      formData = Object.fromEntries(formData);
      formData = Object.entries(formData).map(([dataIndex, value]) => { if(columsTypes[dataIndex] == "number" ) {value = Number(value)} return [dataIndex, value]  } );
      formData = Object.fromEntries(formData);
      const res = await addTableCrud({tableName, row: formData})
      if(res.error) return;
     if(res.result) {cargaDatos()}
    }
  async function cargaDatos () {
     const res = await getTableCrud(tableName)
     if(res.error) return;
     const data = res?.result.sort((a, b) => b?.created_at || 0 - a?.created_at || 0 )
     setDataSource(res?.result)
  }
  async function handleDelRow (id) { 
   console.log({id})
   const res = await delTableCrud({tableName, findParams: {id}})
   if(res.error) return;
   if(res.result) {cargaDatos()}

  }
   async function handleEditRow (id) { 
   console.log({id})
   let data = dataSource.find(item => item.id == id);
   data.editable = false;
   const res = await putTableCrud({tableName, row: data, findParams: {id}})
   if(res.error) return;
   if(res.result) {cargaDatos()}

  }

 function handleSetEditableRow (id, value) {
  const newDataSource = dataSource.map(item => item.id == id ? {...item, editable: value} : item)
  setDataSource(newDataSource)
 }
 function handleSetParam (value, id, dataIndex) {
  console.log({value, id, dataIndex})
  const newDataSource = dataSource.map(item => item.id == id ? {...item, [dataIndex]: value} : item)
  setDataSource(newDataSource)
 }
 
 function handleSaveEditableRow (id) {
   handleEditRow(id)
   handleSetEditableRow(id, false)
 }

  useEffect(() => {

    cargaDatos()
 
   }, [])

  
  

  return (
    <form onSubmit={handleSubmit} ref={formRef}>
    
    <table className="table table-sm table-bordered table-hover  texto-small">

    <thead>
      <tr>  {columns.map( col =>  <th key={col.dataIndex} >{col.title}</th> )}  </tr>
   
      <tr>  
    
        { columns.map( col =>  <th key={col.dataIndex} >
     {           col.inputType === "select" ?
            <select required={col.required} name={col.dataIndex} className="form-control">
              <option value=""></option>
              {col?.selectOptions.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
            </select>
     :    <input required={col.required} name={col.dataIndex} className="form-control" type={col.inputType || 'text'} />
          }
         
      </th> 
      
      )} 
            <th>  <button className="btn btn-success btn-sm" onClick={handleAdd}  ><b>+</b></button></th>

  
      
       </tr>
     
     </thead>
     <tbody>
        {dataSource.map(row => <tr key={row.id}>

              {row.editable && columns.map( col => 
                 <th key={row.id} >
     {           col.inputType === "select" ?
            <select onChange={ (e) => handleSetParam(e.target.value, row.id, col.dataIndex) } value={row?.[col.dataIndex]} className="form-control">
          
              {col?.selectOptions.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
            </select>
     :    <input onChange={ (e) => handleSetParam(e.target.value, row.id, col.dataIndex) } value={row?.[col.dataIndex]}  name={col.dataIndex} className="form-control" type={col.inputType || 'text'} />
          }
         
      </th> 
      
      )
              }

            {!row.editable && columns.map(col => 
           col.inputType === "select" ?
            <td> { col.selectOptions?.find( item => item.value == row?.[col.dataIndex])?.label}
            </td>
            :  <td>{ row?.[col.dataIndex]}</td>
            )}
            <td>
                  <button
                    onClick={() => {
                      handleDelRow(row.id);
                    }}
                    className="btn btn-danger btn-sm"
                    title="Eliminar registro"
                  >
                    <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                  </button>
                  {editableRows && 
                  <>
                 { !row.editable ?
                  <button
                    onClick={() => {
                      handleSetEditableRow(row.id, true);
                    }}
                    className="btn btn-warning btn-sm ml-1"
                    title="Editar registro"
                  >
                    <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon> 
                    </button>
                    :    <button
                    onClick={() => {
                      handleSaveEditableRow(row.id);
                    }}
                    className="btn btn-success btn-sm ml-1"
                    title="Guardar"
                  >
                    <FontAwesomeIcon icon={faSave}></FontAwesomeIcon> 
                    </button>
                    }
                    </>
                  }
                </td>
                

       
        </tr>)}

     </tbody>
   
     </table>
     </form>
  )
}

export default TableCrud