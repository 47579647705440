import TableCrud from '../components/TableCrud'
import useDocumentTitle from '../hooks/useDocumentTitle'


const TablaRelacionesBancos = () => {
  useDocumentTitle('Relaciones Bancos - Interbanking')
   
   const selectTipoOpciones = [
    {value: 'D', label: 'Dolar'},
    {value: 'P', label: 'Pesos'},
    {value: 'C', label: 'Cable'},
  ]
 
    const columns = [
      { title: "Moneda", dataIndex: "moneda", selectOptions: selectTipoOpciones, inputType: "select", required: true },
      { title: "Cod. Cuenta BackOffice", dataIndex: "codCuenta", required: true },
      { title: "Cod. Cuenta Interbanking", dataIndex: "codCuentaIB", required: true },
      { title: "Descripcion Alternativa", dataIndex: "desCuentaAlt", required: false },

    ];


  return (
    <div className="container">
      <div className="row">
       <div className="col-12">
        <div className="card">
          <div className="card-header">
          <span className="ml-1">
            <b>Relaciones Bancos - Interbanking</b>
          </span>
          </div>
          <div className="card-body">
          <TableCrud editableRows={true} tableName={'relacionesBancos'} columns={columns} />
          </div>
        </div>

       </div>
 
      </div>

    </div>
  )
}

export default TablaRelacionesBancos