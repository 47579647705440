import TableCrud from '../components/TableCrud'
import useDocumentTitle from '../hooks/useDocumentTitle'


const TablaComitentesCaucion = () => {
  useDocumentTitle('Lista Omitidos Caucion')
   
   const selectTipoOpciones = [
    {value: 'OMITE_ESPECIE', label: 'Omitir Especie por comitente (cód. Caj. Val.)'},
    {value: 'OMITE_COMITENTE', label: 'Omitir Comitente'},
  ]
 
    const columns = [
      { title: "Cod. Comitente", dataIndex: "codComitente" },
      { title: "Tipo", dataIndex: "tipo", selectOptions: selectTipoOpciones, inputType: "select", required: true },
      { title: "Valor", dataIndex: "valor" },
    ];


  return (
    <div className="container">
      <div className="row">
       <div className="col-12">
        <div className="card">
          <div className="card-header">
          <span className="ml-1">
            <b>Lista Omitidos en Distribución Caución</b>
          </span>
          </div>
          <div className="card-body">
          <TableCrud tableName={'tablaOmitidosCaucion'} columns={columns} />
          </div>
        </div>

       </div>
 
      </div>

    </div>
  )
}

export default TablaComitentesCaucion