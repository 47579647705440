import React, { useState, useEffect } from "react";
//import getReqNoCubiertos from "../services/getReqNoCubiertos";
import shortid from "shortid"
import useServices from "../hooks/useServices";
const REACT_APP_CAJVAL_GTIAS = process.env.REACT_APP_CAJVAL_GTIAS;
const REACT_APP_CAJVAL_DEPOSITANTE = process.env.REACT_APP_CAJVAL_DEPOSITANTE;

const ReqNocub = ({addTransfWidgets}) => {
  const [datos, setDatos] = useState([]);
  const {getReqNoCubiertos} = useServices();


  const roundUp = (valor, divisor) => {
    let rdo = divisor - (valor % divisor) + valor;
    if (valor % divisor == 0) {
      rdo = valor;
    }

    return rdo;
  };
  const extraeNumEspecie = (str) => {
    try {
    return str.split("(")[1].split(")")[0];
    } catch (e) { return}

  };

  async function cargaDatos() {
    let reqNoc = await getReqNoCubiertos();
    //console.log({ reqNoc });
    let reqNocTransf = [];
    if (!reqNoc.error) {
        reqNoc.pop();
        reqNoc.forEach((element, index) => {
            if(index > 1) {
                const cuenta = element[0];
                const tiporeq = element[1];
                const objNeg = element[2];
                const nocubierto = element[6]*1;
                const enviamos = nocubierto
                const nroEspecie = extraeNumEspecie(tiporeq);
                if(nroEspecie) {
                reqNocTransf.push({cuenta, tiporeq, objNeg, nocubierto, enviamos, nroEspecie, id: shortid.generate()})
              }

            }
        });
       // console.log({reqNocTransf})
        setDatos(reqNocTransf)
    }
  }
  const handleRedondeo = () => {
    const newReqNocTransf = datos.map( item => {item.enviamos = roundUp(item.nocubierto, 100); return item;})
    setDatos(newReqNocTransf)
  }
 
  const handleInputChange = (valor, index) => {

    let newDatos = [...datos];
    newDatos[index].enviamos = valor;
    setDatos(newDatos)

  }

  const handleAddItem =  (index) => {
      //console.log(index)
      const dato = datos[index];
      //console.log(dato)
      const transf = {tipo: "(I/E)", subcuenta: `${REACT_APP_CAJVAL_DEPOSITANTE}/${dato.cuenta}`, contraparte: `${REACT_APP_CAJVAL_GTIAS}/${dato.cuenta}`, cantidad: dato.enviamos, nroEspecie: dato.nroEspecie, match: "N", classString: 'table-warning',  tipoOperacion: "GARANTIA"}
      let newDatos = [...datos];
      newDatos.splice(index, 1);
      setDatos(newDatos)
      addTransfWidgets([transf]);
  }

  useEffect(() => {
    cargaDatos();
  }, []);

  return (
    <div className="card">
      <div className="card-header grey">
        <b>Requerimientos No Cubiertos</b>
   
        <button type="button" className="btn btn-secondary btn-sm float-right">
          {new Date().toLocaleDateString()}
        </button>
        <button type="button" onClick={() => handleRedondeo() } className="btn btn-secondary btn-sm float-right mr-1">
          Redondear
        </button>
      </div>
      <div className="card-body">
        <table className="table table-sm table-bordered table-hover">
          <thead>
            <tr>
              <th>Cuenta</th>
              <th>Tipo Req.</th>
              <th>Obj. Neg.</th>
              <th>No cubierto</th>
              <th>Enviamos</th>
            </tr>
          </thead>
          <tbody>
            {datos.map((item, index) => (
              <tr key={item.id}>
                <td>{item.cuenta}</td>
                <td>{item.tiporeq}</td>
                <td>{item.objNeg}</td>
                <td>{item.nocubierto}</td>
              
                <td>
                  {" "}
                  <input type="number" onChange= {(e) => handleInputChange(e.target.value, index) } value={item.enviamos} className="form-control-sm form-control" />{" "}
                </td>
                <td>
                <button type="button" onClick={() => handleAddItem(index) } className="btn btn-warning btn-sm">+</button>
                </td>
                
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ReqNocub;
