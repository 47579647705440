import React, { useState, useEffect } from "react";
//import getGarantiaDisponible from "../services/getGarantiaDisponible";
import shortid from "shortid";
import useServices from "../hooks/useServices";
import { formatoFecha } from "../lib/utils";
const REACT_APP_CAJVAL_DEPOSITANTE = process.env.REACT_APP_CAJVAL_DEPOSITANTE;
const REACT_APP_IE_GTIA_DISP_DIRECTA = process.env.REACT_APP_IE_GTIA_DISP_DIRECTA === 'true';


const GarantiaDisp = ({ addTransfWidgets }) => {
  const [datos, setDatos] = useState([]);
  const {getGarantiaDisponible} = useServices();

  async function cargaDatos() {
    let garantiaDisp = await getGarantiaDisponible();
    //console.log({ garantiaDisp });
    let garantiaDispTransf = [];
    if (!garantiaDisp.error) {
      garantiaDisp.pop();
      garantiaDisp.forEach((element, index) => {
        if (index > 1) {
          const cuenta = element[0];
          const especie = element[1];
          const disponible = element[3] * 1;
          const traemos = disponible;
       

          if (especie.indexOf("Credito - Posicion") == -1 && extraeNumEspecie(especie)) {
            garantiaDispTransf.push({ cuenta, especie, disponible, traemos, id: shortid.generate() });
          }
        }
      });
      //console.log({ garantiaDispTransf });
      setDatos(garantiaDispTransf);
    }
  }

  const handleInputChange = (valor, index) => {
    let newDatos = [...datos];
    newDatos[index].traemos = valor;
    setDatos(newDatos);
  };

  const handleAddItem = (index) => {
    //console.log(index);
    const datosTransf = datos[index];
    const despositante = REACT_APP_CAJVAL_DEPOSITANTE;
    const contraparte1 = `9${'0'.repeat(3-despositante.length)+despositante}/${datosTransf.cuenta}`
    const contraparte2 = `${despositante}/${datosTransf.cuenta}`
    const subcuenta = `${despositante}/3`
    const nroEspecie = extraeNumEspecie(datosTransf.especie);
    if(!nroEspecie) return
    const cantidad = datosTransf.traemos;
    const classString = 'table-success';
    if(!REACT_APP_IE_GTIA_DISP_DIRECTA) {
    const transf1 = { tipo: "(I/R)", subcuenta, nroEspecie, cantidad, contraparte: contraparte1, classString, id: datosTransf.id, match: 'N', tipoOperacion: "GARANTIA" };
    const transf2 = { tipo: "(I/E)", subcuenta, nroEspecie, cantidad, contraparte: contraparte2, classString, id: datosTransf.id, match: 'N', tipoOperacion: "GARANTIA" };
    addTransfWidgets([transf1,transf2]);
    } else {
      const transf1 = { tipo: "(I/R)", subcuenta: contraparte2, nroEspecie, cantidad, contraparte: contraparte1, classString, id: datosTransf.id, match: 'N', tipoOperacion: "GARANTIA" };
      addTransfWidgets([transf1]);
    }
    let newDatos = [...datos];
    newDatos.splice(index, 1);
    setDatos(newDatos)

 
  };

  const extraeNumEspecie = (str) => {
    try {
    return str.split("(")[1].split(")")[0];
    } catch (e) { return}

  };

  useEffect(() => {
    cargaDatos();
  }, []);

  return (
    <div className="card">
      <div className="card-header grey">
        <b>Garantia Disponible</b>
        <button type="button" className="btn btn-secondary btn-sm float-right">
          {formatoFecha(new Date())}
        </button>
      </div>
      <div className="card-body">
        <table className="table table-sm table-bordered table-hover">
          <thead>
            <tr>
              <th>Cuenta</th>
              <th>Especie</th>
              <th>Disponible</th>
              <th>Traemos</th>
            </tr>
          </thead>
          <tbody>
            {datos.map((item, index) => (
              <tr key={item.id}>
                <td>{item.cuenta}</td>
                <td>{item.especie}</td>
                <td>{item.disponible}</td>
                <td>
                  {" "}
                  <input
                    // style={{ maxWidth: "70px" }}
                    type="number"
                    onChange={(e) => handleInputChange(e.target.value, index)}
                    value={item.traemos}
                    className="form-control-sm form-control"
                  />{" "}
                </td>
                <td>
                  <button type="button" onClick={() => handleAddItem(index)} className="btn btn-success btn-sm">
                    +
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GarantiaDisp;
