import { MODULOS_HAB } from "../lib/modulesHab";

const hasDerivaciones = process.env.REACT_APP_BYMA_DERIVACIONES  === "true";
const REACT_APP_SYS_BO = process.env.REACT_APP_SYS_BO;
const REACT_ALYC_INTEGRAL = process.env.REACT_APP_ALYC_INTEGRAL === "true";

console.log({MODULOS_HAB})
const menuDistribucionDerivacines = MODULOS_HAB.DISTRIBUCION_DERIVACIONES ? [{ title: "Distribución de Derivaciones", link: "/distribucionesBYMA", mercado: "BYMA" }] : [];
const menuDerivaciones = hasDerivaciones ? [  { title: "Panel de Derivaciones", link: "/panelDerivaciones", mercado: "BYMA" }, ...menuDistribucionDerivacines] : [];
const menuSaldosBOP = MODULOS_HAB.SALDOS_BOP ? [{ title: "Saldos AUNE", link: "/saldosbop", mercado: "BYMA" }, { title: "Control Saldos AUNE - CI", link: "/controlSaldosAUNE", mercado: "BYMA" }] : [];
const menuSDIB = MODULOS_HAB.SDIB ? [ { title: "SDIB", link: "/sdib", mercado: "BYMA" }] : [];
const menuControlNominales = MODULOS_HAB.CONTROL_NOMINALES ? [   { title: "Control Nominales", link: "/controlnominales", mercado: "BYMA" }] : [];
const menuVenCaucion = MODULOS_HAB.VENCIMIENTOS_CAUCION ? [    { title: "Vencimientos Caucion", link: "/posicauc", mercado: "BYMA" }] : [];
const menuVC = MODULOS_HAB.VC ? [  { title: "Venta en corto", link: "/vc" , mercado: "BYMA"}] : [];
const menuOperacionesMTR = MODULOS_HAB.OPERACIONES_MTR ? [   { title: "Panel Operaciones", link: "/panelAP5", mercado: "MTR" }] : [];
const menuPosicionesMTR = MODULOS_HAB.OPERACIONES_MTR ? [   { title: "Posiciones", link: "/posicionesMTR", mercado: "MTR" }] : [];
const menuGyMMTR = MODULOS_HAB.OPERACIONES_MTR ? [   { title: "Garantias y Margenes", link: "/garantiasYMargenesMTR", mercado: "MTR" }] : [];
const menuCanceladasMTR = MODULOS_HAB.OPERACIONES_MTR ? [   { title: "Canceladas", link: "/canceladasMTR", mercado: "MTR" }] : [];
const menuConciliaTenenciaCV = MODULOS_HAB.CONCILIA_TENENCIA_CV ? [{ title: "Concilia Tenencia CV", link: "/conciliaTenenciaCV", mercado: "BYMA" }] : [];
const menuRG624 = MODULOS_HAB.RG624 ? [    { title: "RG624", link: "/rg624", mercado: "BYMA" }] : [];
const menuControlSaldosVB = REACT_APP_SYS_BO == "VISUAL_BOLSA" ?  [    { title: "Control Saldos (VB)", link: "/controlSaldosVB", mercado: "BYMA" }] : [];
const menuConciliaOpersVB = MODULOS_HAB.CONCILIA_OPERS_VB ? [ { title: "Concilia Operaciones VB", link: "/conciliaOpersVB", mercado: "BYMA" }] : [];
const menuNominalesFCI = MODULOS_HAB.NOMINALES_FCI ? [ { title: "Nominales FCI", link: "/nominalesFCI", mercado: "BYMA" }] : [];
const menusALYC_INTEGRAL = REACT_ALYC_INTEGRAL ? [{ title: "Control Saldos AN", link: "/controlSaldosAUNEAN", mercado: "MULTI" }, { title: "Control Saldos (Comitente)", link: "/controlSaldosAUNEANPorComitente", mercado: "MULTI" } , { title: "Control Nominales AN", link: "/controlNominalesAUNEAN", mercado: "MULTI" }] : [];
const menuSaldosAUNE = (MODULOS_HAB.SALDOS_AUNE && REACT_APP_SYS_BO == "AUNE") ? [{ title: "Carga Saldos Trader", link: "/cargaSaldosTrader", mercado: "BYMA" }, { title: "Control Saldos AUNE", link: "/controlSaldosAUNEAN", mercado: "MULTI" }, { title: "Control Nominales AUNE", link: "/controlNominalesAUNEAN", mercado: "MULTI" }] : [];
const menuNominalesBYMA = MODULOS_HAB.NOMINALES_BYMA ? [{ title: "Nominales BYMA", link: "/nominales", mercado: "BYMA" }] : [];
const menuEjerciciosOpciones = MODULOS_HAB.EJERCICIOS_OPCIONES ? [ { title: "Ejercicios de Opciones", link: "/ejercicios", mercado: "BYMA" }] : [];
const menuPosOpciones = MODULOS_HAB.POSICION_OPCIONES ? [{ title: "Posición Opciones", link: "/posiopc", mercado: "BYMA" }] : [];
const menuGtiasyCobBYMA = MODULOS_HAB.GTIAS_Y_COB_BYMA ? [  { title: "Garantias y Coberturas", link: "/garantiasYCoberturasBYMA", mercado: "BYMA" }] : [];
const menuDayTrading = MODULOS_HAB.DAY_TRADING ? [ { title: "Day Trading", link: "/daytrading", mercado: "BYMA" }] : [];
const tablaSizesMTR = MODULOS_HAB.OPERACIONES_MTR ? [  { title: "Tabla Sizes Contratos", link: "/tablaSizesMTR", mercado: "MTR" }] : [];
const menuResumenSaldos = MODULOS_HAB.RESUMEN_SALDOS ? [  { title: "Resumen Saldos", link: "/resumenSaldosMdos" }] : [];
const tablaComitentes = MODULOS_HAB.TABLA_COMITENTES ? [    { title: "Tabla Comitentes", link: "/comitentes" }] : [];
const tablaFeriados = MODULOS_HAB.TABLA_FERIADOS ? [  { title: "Tabla Feriados", link: "/feriados" },] : [];
const tablaFCIs = MODULOS_HAB.TABLA_FCIS ? [  { title: "Tabla FCIs", link: "/tablaFCIs" },] : [];
const saldosConsolidados = MODULOS_HAB.SALDOS_CONSOLIDADOS ? [  { title: "Saldos Consolidados", link: "/saldosConsolidados" }] : [];
const saldosEmpresa = MODULOS_HAB.SALDOS_EMPRESA ? [  { title: "Saldos Empresa", link: "/saldosEmpresa" }] : [];
//console.log({tablaFCIs})
export const menuList = {
  admin: [
    { title: "Panel de Operaciones", link: "/panel", mercado: "BYMA" },
    ...menuDerivaciones,
    { title: "Distribución de Cauciones", link: "/distribucionesCaucionBYMA", mercado: "BYMA" },
    { title: "Panel SENEBI", link: "/panelSB", mercado: "BYMA" },
   ...menuNominalesBYMA,
    ...menuNominalesFCI,
    ...menuGtiasyCobBYMA,
    ...menusALYC_INTEGRAL,
    ...menuSaldosAUNE,
    ...menuConciliaTenenciaCV,
    ...menuConciliaOpersVB,
    { title: "Panel MAV", link: "/panelMAV", mercado: "MAV" },
    { title: "Derivaciones MAV", link: "/panelDerivacionesMAV", mercado: "MAV" },
    { title: "Nominales MAV", link: "/nominalesMAV", mercado: "MAV" },
    { title: "Distribuciones MAV", link: "/distribucionesMAV", mercado: "MAV" },
    { title: "Tabla Fondos", link: "/fondos", mercado: "MAV" },
    ...menuControlNominales,
    ...menuSaldosBOP,
    ...menuControlSaldosVB,
    ...menuSDIB,
    ...menuVenCaucion,
    ...menuPosOpciones,
    ...menuEjerciciosOpciones,
    ...menuVC,
    ...menuDayTrading,
    ...menuRG624,
  ...menuOperacionesMTR,
    ...menuCanceladasMTR,
  ...menuPosicionesMTR,
  ...menuGyMMTR,
 ...tablaSizesMTR,
 ...saldosConsolidados,
 ...saldosEmpresa,
  ...tablaComitentes,
 ...tablaFeriados,
 ...tablaFCIs,
    { title: "Estado de sesiones", link: "/marketSesions" },
    { title: "Gestion de Usuarios", link: "/usersPanel" },
  ],
  backoffice: [
    { title: "Panel de Operaciones BYMA", link: "/panel", mercado: "BYMA" },
    ...menuDerivaciones,
    { title: "Distribución de Cauciones", link: "/distribucionesCaucionBYMA", mercado: "BYMA" },
    { title: "Panel SENEBI", link: "/panelSB", mercado: "BYMA" },
    ...menuNominalesBYMA,
    ...menuNominalesFCI,
    ...menuGtiasyCobBYMA,
    ...menusALYC_INTEGRAL,
    ...menuSaldosAUNE,
    ...menuConciliaTenenciaCV,
    ...menuConciliaOpersVB,
    { title: "Panel MAV", link: "/panelMAV" , mercado: "MAV"},
    { title: "Derivaciones MAV", link: "/panelDerivacionesMAV", mercado: "MAV" },
    { title: "Nominales MAV", link: "/nominalesMAV", mercado: "MAV" },
    { title: "Distribuciones MAV", link: "/distribucionesMAV", mercado: "MAV" },
    { title: "Tabla Fondos", link: "/fondos", mercado: "MAV" },
    ...menuControlNominales,
    ...menuSaldosBOP,
    ...menuControlSaldosVB,
    ...menuSDIB,
    ...menuVenCaucion,
    ...menuPosOpciones,
    ...menuEjerciciosOpciones,
    ...menuVC,
    ...menuDayTrading,
    ...menuRG624,
    ...menuOperacionesMTR,
    ...menuCanceladasMTR,
    ...menuPosicionesMTR,
    ...menuGyMMTR,
    ...tablaSizesMTR,
    ...saldosConsolidados,
   ...saldosEmpresa,
   ...tablaComitentes,
 ...tablaFeriados,
 ...tablaFCIs,
    { title: "Estado de sesiones", link: "/marketSesions" },
  ],
  trader: [
    { title: "Panel de Operaciones", link: "/panelTrader", mercado: "BYMA" },
    { title: "Posición Opciones", link: "/posiOpcTrader", mercado: "BYMA" },
    { title: "Vencimientos Cauciones", link: "/posicaucTrader", mercado: "BYMA" },
    { title: "Garantias y Coberturas", link: "/garantiasYCoberturasBYMATrader", mercado: "BYMA" },,
    { title: "Posición Cauciones", link: "/neteoCaucTrader", mercado: "BYMA" },
    { title: "Ejercicios Opciones", link: "/ejerciciosTrader", mercado: "BYMA" },
    { title: "Venta en corto", link: "/vcTrader" , mercado: "BYMA"},
    { title: "Panel de Operaciones", link: "/panelAP5Trader", mercado: "MTR" },
    { title: "Canceladas", link: "/canceladasMTRTrader", mercado: "MTR" },
    { title: "Posiciones", link: "/posicionesMTRTrader", mercado: "MTR" },
    { title: "Garantias y Margenes", link: "/garantiasYMargenesMTRTrader", mercado: "MTR" },
  ],
  an: [
    { title: "Panel de Operaciones", link: "/panelAN", mercado: "BYMA" },
    { title: "Panel SENEBI", link: "/panelSBAN", mercado: "BYMA" },
    { title: "Vencimientos Cauciones", link: "/venCaucAN", mercado: "BYMA" },
    { title: "Posicion Opciones", link: "/posiOpcAN", mercado: "BYMA" },
    { title: "Panel MAV", link: "/panelMAVAN", mercado: "MAV" },
    { title: "Control Nominales AN", link: "/controlNominalesAN", mercado: "MULTI" },
    { title: "Control Saldos AN", link: "/controlSaldosAN", mercado: "MULTI" },
]
};
