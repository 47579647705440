export const conceptos = {
  AL: "CTAS AG LIQ LEG",
  AP: "ACREENCIAS PRESTAMOS",
  BD: "BONIF. DERECHOS BYMA",
  BF: "BONIF. OP.TRAD.",
  BP: "BIE. PERSONALES",
  CA: "CAU/C.",
  CC: "CERTIFICADO CARBONO",
  CD: "CHQ. DIFERIDOS",
 // CI: "CDO INMEDIATO",
  CL: "COMISION LETRAS",
  CM: "COBRO MEMBRESIA",
  CN: "CONTADO",
  CO: "COM. COLOCACION",
  CP: "COLOC.PRIMARIAS",
  CQ: "COMISIONES QUANTEX",
  CR: "CREDITOS ACDYS",
  CS: "COMPENSACION POR DIF",
  CT: "COLOC. TITULOS",
  //CV: "CAUCION VTO.",
  //D0: "DERIV. CYPB INM",
  DB: "DER/LIST CH.DIF",
  //DC: "DERIV. CONTADO",
 // DI: "DERIV. CDO.INM",
  DK: "DATA MARKET",
  DL: "LINEAS DIRECTAS",
  DM: "DERECHO BYMA",
  DP: "DERIV.COLOC.PRIMARIA",
  DV: "DEREC. DERIV.OPERAC.",
  EI: "ERROR IMPUTACION",
  FA: "FACTURA CVSA",
  //FC: "CAUC.PASE INM.",
  FD: "FUTURO DIVISAS",
  FI: "FUTURO INDICES",
  FM: "FACTURA IAMC",
  FO: "DIV. FIRM. OPE.",
  FT: "FACTURA TECVAL",
  FU: "FUTUROS",
  FV: "CAUC.PASE VTO.",
  GB: "GTOS BANCARIOS",
  II: "INS.CURSOS IAMC",
  IM: "IND. MAYORISTA",
  IN: "INDICES",
  LD: "LICIT. DOLARES",
  LF: "LOTES FUTUROS",
  LL: "CANON LICITACION",
  LO: "LIQ. COLOCACION",
  LP: "LICIT. PESOS",
  MA: "MATBA",
  NC: "NOTA CRED.CVSA",
  NE: "CANON SENEBI",
  NM: "NOTA CRED. IAMC",
  NT: "NOTA CRED.TECVAL",
  OC: "OPER. CONTINUA",
  OE: "DEREC. OPER.S.CONTR.",
  OM: "CARGO SERVICIO OMS",
  OP: "OPCIONES",
  PL: "PLAZO POR LOTES",
  PR: "PREST.TIT.INM.",
  PV: "PREST.TIT.VTO.",
  RD: "SERV.RED DIG.",
  RE: "SERV.REUTERS TR",
  RN: "RETRIBUCION M.MAKERS",
  RS: "RESCATE",
  SA: "SERV. ASISTENC.",
  SC: "SERV GEST. DE PAGOS",
  SE: "SEG. DE CAUCION",
  SS: "SUSCRIPCION",
  TA: "TRANSM.ARCHIVO",
  TC: "TERMINALES CONS",
  TE: "RED CONM.TELEF.",
  TP: "TRANSF. AUD. RES/816",
  VC: "VTA.CORTO CDO",
  VS: "VARIOS",
  VV: "VTA.CORTO VTO",
  XM: "NTEO C/A O-MCDO",
  XT: "NETEO OTRO MCDO T/F",
  XX: "NETEO OTRO MCDO",
};
