import React, { useState } from "react";
import Modal from "react-modal";
import TableCrud from "./TableCrud";


const ModalRecortesAforos = () => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const columns = [{title: "Dias al venciento (menor o igual que)", dataIndex: 'dtv', inputType: "number", required: true}, {title: "Recorte Aforo", dataIndex: 'valor',  inputType: "number", required: true}]


  function openModal() {
    setIsOpen(true);

  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      "z-index": "99"
    },
  };


  return (
    <>
      <button className="btn  btn-secondary btn-sm float-right mr-1" onClick={openModal}>
        Recortes Aforos
      </button>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Modal"
      
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Recortes aforos</h5>
            <button type="button" className="close" aria-label="Close" onClick={closeModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
          <TableCrud tableName={'recortesAforos'} columns={columns} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalRecortesAforos;
