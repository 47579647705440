import React, { useState, useEffect } from "react";
import FileUploadButton from "./FileUploadButtonSimple";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import getConciliacionBancosIB from "../services/getConciliacionBancosIB";



const ConciliaBancosComponent = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});
  
    function handleUploadSucess() {
   cargaDatos();
  }
  const formatNumero = (numero) => {
    if(numero === null) return "No encontrado";
    if (isNaN(numero)) return "";
    if (numero == undefined) return ""
    return numero.toLocaleString("de");
  }
  const diccMonedas = { P: "Pesos", D: "Dólar", C: "Cáble" };
  async function cargaDatos() {
    setIsLoading(true)
    const res = await getConciliacionBancosIB();
    if (res.error) return;
    setData(res)
    setIsLoading(false)
  }

  useEffect(async () => {
    cargaDatos();
  }, []);

  function diffSaldos(saldo1, saldo2) {
    if(saldo1 !== null && saldo2 !== null) {
      return saldo1 - saldo2
    } else {
        return ""
    }


  }

  return (
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col">
            <h5 className="card-title">Conciliación Bancos</h5>
          </div>
          <div className="col">
            <Link target={"_blank"} open to="/relacionesBancos">
              <button className="btn btn-sm  btn-secondary float-right ml-2" renderAs="button">
                <span>Relaciones bancos</span>
              </button>
            </Link>
            <FileUploadButton
              btnClassName=" btn btn-sm btn-secondary float-right"
              endPoint="/api/conciliacionBancaria/uploadAUNE"
              fileName="saldosBancosBackOffice.xlsx"
              callBack={handleUploadSucess}
              icon={<FontAwesomeIcon className="ml-2" icon={faUpload} />}
              btnText="Cargar Bancos AUNE"
            />
          </div>
        </div>
      </div>
      <div className="card-body">
        <table className="table table-sm table-bordered table-hover ">
          <thead>
            <tr>
              <th scope="col">Cuenta</th>
              <th scope="col">Cod. Cuenta IB</th>
              <th scope="col">Cod. Cuenta BackOffice</th>
              <th scope="col">Moneda</th>
              <th scope="col">Saldo Banco BackOffice</th>
              <th scope="col">Saldo Banco IB</th>
              <th scope="col">Diferencia</th>
              
            </tr>
          </thead>
          <tbody>
            {
            isLoading ? <tr><td colSpan="7" className="text-center"><h3>Cargando...</h3></td></tr> 
            :
            data?.saldosConciliados?.map((linea) => (
              <tr key={linea.codCuentaIB+linea.moneda}>
                <td>{linea.cuenta}</td>
                <td>{linea.codCuentaIB}</td>
                <td>{linea.codCuentaBackOffice}</td>
                <td>{ diccMonedas[linea.moneda]}</td>
                <td className="text-right">{ formatNumero(linea.saldoBancoBackOffice)}</td>
                <td className="text-right">{ formatNumero(linea.saldoBancoIB)}</td>
                <td className="text-right">{ formatNumero(diffSaldos( linea.saldoBancoIB, linea.saldoBancoBackOffice,))}</td>
              </tr>
            ))}
          </tbody>
        </table>


      </div>
    </div>
  );
};

export default ConciliaBancosComponent;
