
const baseURL = process.env.REACT_APP_API_URL;

const getDistribucionesCaucionesBYMA = async (options, {tomadoraCierre = true, garantiaGara = true, prioridadCaucUSD = false}) => {
  let json =  []
  try {
    const res = await fetch(`${baseURL}distribuciones/BYMA/cauciones?tomadoraCierre=${tomadoraCierre == true ? 'true' : 'false'}&garantiaGara=${garantiaGara == true ? 'true' : 'false'}&prioridadCaucUSD=${prioridadCaucUSD}`, options);
    json = await res.json();
  } catch (e) {
    return { error: e };
  }

  return json;
};

export default getDistribucionesCaucionesBYMA;
