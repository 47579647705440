import React, { useEffect, useState } from "react";
import CardTemplate from "../components/CardTemplate";
import useDocumentTitle from "../hooks/useDocumentTitle";
import useServices from "../hooks/useServices";
const  REACT_APP_SYS_BO = process.env.REACT_APP_SYS_BO;
const REACT_APP_EMS_API = process.env.REACT_APP_EMS_API === 'true';

const MarketSesions = () => {
  useDocumentTitle("Sesiones");
  const { getMarketSesions, AP5Reconnect, MAVConnect, MAVDisconnect, MAVForceDisconnect, reconectMAVAutomatico, getActiveOpersSource, switchOpersSource, reconectAPIAUNE, reconnectEMS, reloadListadoTitulosValores, reloadPreciosTitulosValores } = useServices();
  const updateSesionsStatus = async () => {
    const res = await getMarketSesions();
    if (res.error) return;
    //console.log(res);
    setStatus(res);
  };
  const [status, setStatus] = useState({});
  const [AP5ReconnectSatus, setAP5ReconnectSatus] = useState('')
  const [selectedSource, setSelectedSource] = useState(null);
  const [MAVSatus, setMAVSatus] = useState('')
  const [isLoadingTV, setIsLoadingTV] = useState(false)
  const [isLoadingPreciosTV, setIsLoadingPreciosTV] = useState(false)
  async function handleAP5reconnect () {
    const res = await AP5Reconnect();
    if(res.error) return
    const {Status} = res;
    setAP5ReconnectSatus(Status);

  }

  async function handleMAVConnect () {
    const res = await MAVConnect();
    if(res.error) return
    const {status} = res;
    setMAVSatus(status);
    updateSesionsStatus();

  }
  async function handleMAVDisconnect () {
    const res = await MAVDisconnect();
    if(res.error) return
    const {status} = res;
    setMAVSatus(status);
    updateSesionsStatus();

  }
  async function handleMAVForceDisconnect () {
    const res = await MAVForceDisconnect();
    if(res.error) return
    const {status} = res;
    setMAVSatus(status);
    updateSesionsStatus();

  }

  async function handleRecargarMAVAutomatico () {
    await reconectMAVAutomatico();
    updateSesionsStatus();
  }

  async function handleReconectAUNEAPI () {
    await reconectAPIAUNE();
    updateSesionsStatus();
  }

  
  async function handleReconectEMS () {
    await reconnectEMS();
    updateSesionsStatus();
  }
  async function handleReloadListadoTitulosValores () {
    setIsLoadingTV(true)
    const res = await reloadListadoTitulosValores();
    if(res.error) return
    setIsLoadingTV(false)
    alert(res.status)
  }
  async function handleReloadPreciosTitulosValores () {
    setIsLoadingPreciosTV(true)
    const res = await reloadPreciosTitulosValores();
    if(res.error) return
    setIsLoadingPreciosTV(false)
    alert(res.status)
  }

  async function updateSourceSelected () {
   const res = await getActiveOpersSource();
   console.log(res)
   if(res.error) return;
   const {ACTIVE_SWITCH} = res;
   if(ACTIVE_SWITCH) {setSelectedSource(ACTIVE_SWITCH)}

  }
  async function establecerSource() {
   const res = await switchOpersSource(selectedSource);
   if(res.error) return
   if(res.status == "OK") {alert("Se establecio correctamente la source: "+res.source)}
   console.log(res)
  }


  useEffect(() => {
    updateSesionsStatus();
    updateSourceSelected();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-0 col-lg-3 col-md-2"></div>
        <div className="col-sm-12 col-lg-6 col-md-8">
          <CardTemplate title={"Sesiones"} description={"Estado de conexiones con sistemas de mercados"}>
            <table className="table table-sm table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col">Sesion</th>
                  <th scope="col">Estado</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Anywhere Portfolio 5 API</td>
                  <td style={{ maxWidth: "22px" }} >
                    {status.loginAP5 ? (
                      <span class="badge badge-pill badge-success">Activa</span>
                    ) : (
                      <span class="badge badge-pill badge-danger">Sin sesión</span>
                    )}
                  </td>
                  <td   className="text-center">
                    <button onClick={() => handleAP5reconnect()} className="btn btn-sm btn-success">
                      Reconectar
                    </button>
                  </td>
                  {AP5ReconnectSatus != "" && <td>{AP5ReconnectSatus}</td>}
                </tr>
                <tr>
                  <td>BYMA - GARA</td>
                  <td style={{ maxWidth: "22px" }} >
                    {status.loginGARA ? (
                      <span class="badge badge-pill badge-success">Activa</span>
                    ) : (
                      <span class="badge badge-pill badge-danger">Sin sesión</span>
                    )}
                  </td>
                  <td><button disabled={isLoadingTV} title="Actualizar listado de titulos valores" onClick={handleReloadListadoTitulosValores} className="btn btn-sm btn-success">Recargar Tit. Val.</button>
                  <button disabled={isLoadingPreciosTV} title="Actualizar precios de titulos valores" onClick={handleReloadPreciosTitulosValores} className="btn btn-sm btn-success ml-1">Recargar Precios</button>
                  
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>BYMA - SDIB</td>
                  <td style={{ maxWidth: "22px" }} >
                    {status.loginSDIB ? (
                      <span class="badge badge-pill badge-success">Activa</span>
                    ) : (
                      <span class="badge badge-pill badge-danger">Sin sesión</span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>BYMA - SLYC</td>
                  <td style={{ maxWidth: "22px" }} >
                    {status.loginSLYC ? (
                      <span class="badge badge-pill badge-success">Activa</span>
                    ) : (
                      <span class="badge badge-pill badge-danger">Sin sesión</span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>MAV</td>
                  <td style={{ maxWidth: "22px" }} >
                    {status.loginMAV ? (
                      <span class="badge badge-pill badge-success">Activa</span>
                    ) : (
                      <span class="badge badge-pill badge-danger">Sin sesión</span>
                    )}
                  </td>
                  <td   className="text-center">
              
                    {status.loginMAV ? (
                      <button onClick={() => handleMAVDisconnect()} className="btn btn-sm btn-danger">
                        {"Deconectar"}
                      </button>
                    ) : (
                      <>
                        <button onClick={() => handleMAVConnect()} className="btn btn-sm btn-success">
                          {"Conectar"}
                        </button>{" "}
                        <button onClick={() => handleMAVForceDisconnect()} className="btn btn-sm btn-danger ml-1">
                          {"Forzar desconexión"}
                        </button>{" "}
                      </>
                    )}{" "}
                  </td>
                  {MAVSatus != "" && <td>{MAVSatus}</td>}
                </tr>
                <tr>
                  <td>MAV - Automatico</td>
                  <td  >
                    {status.loginMAVAutomatico ? (
                      <span class="badge badge-pill badge-success">Cargado</span>
                    ) : (
                      <span class="badge badge-pill badge-danger">Sin credenciales</span>
                    )}
                  </td>
                  <td  className="text-center">
                    <button className="btn btn-sm btn-success" onClick={handleRecargarMAVAutomatico}>
                      Recargar
                    </button>
                  </td>
                </tr>
                {REACT_APP_SYS_BO == "AUNE" && <tr>
                  <td>API AUNE</td>
                  <td  >
                    {status.loginAUNEAPI ? (
                      <span class="badge badge-pill badge-success">Conectado</span>
                    ) : (
                      <span class="badge badge-pill badge-danger">No conectado</span>
                    )}
                  </td>
                  <td  className="text-center">
                    <button className="btn btn-sm btn-success" onClick={handleReconectAUNEAPI}>
                      Reconectar
                    </button>
                  </td>
                </tr>}
                {REACT_APP_EMS_API && <tr>
                  <td>EMS</td>
                  <td  >
                    {status.loginEMS ? (
                      <span class="badge badge-pill badge-success">Conectado</span>
                    ) : (
                      <span class="badge badge-pill badge-danger">No conectado</span>
                    )}
                  </td>
                  <td  className="text-center">
                    <button className="btn btn-sm btn-success" onClick={handleReconectEMS}>
                      Reconectar
                    </button>
                  </td>
                </tr>}
              </tbody>
            </table>
          </CardTemplate>
        </div>
        <div className="col-sm-0 col-lg-3 col-md-2"></div>
      </div>
      <div className="row">
      <div className="col-sm-0 col-lg-3 col-md-2"></div>
      <div className="col-sm-12 col-lg-6 col-md-8">
      <CardTemplate title={"Sources"} description={"Fuentes de informacion"}> 
      <table className="table table-sm table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col">Mercado</th>
                  <th scope="col">Segmento</th>
                  <th scope="col">Fuente</th>
                  <th></th>
                </tr>
        </thead>
        <tbody>
          <tr>
            <td>BYMA</td>
            <td>Contado Garantizado, Cauciones</td>
            <td> <select onChange={e => setSelectedSource(e.target.value)} className="form-control" value={selectedSource}> <option value="GARA">GARA</option>  <option value="SDIB">SDIB</option> </select> </td>
            <td className="text-center"><button onClick={establecerSource} className="btn btn-secondary btn-sm">Establecer</button></td>
          </tr>
        </tbody>
      </table>

      </CardTemplate>
      </div>
      <div className="col-sm-0 col-lg-3 col-md-2"></div>

          


      </div>
    </div>
  );
};

export default MarketSesions;
