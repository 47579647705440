import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faTrashAlt, faCopy, faSave, faEdit } from "@fortawesome/free-solid-svg-icons";
//import {  getFondos } from "../services/listaFondos";
import useServices from "../hooks/useServices";
import FileUploadButton from "./FileUploadButton";
const baseURL = process.env.REACT_APP_API_URL;


const TablaAforosBYMA = () => {
  const [datos, setDatos] = useState([]);
  const [especie, setEspecie] = useState("");
  const [codCajVal, setCodCajVal] = useState("");
  const [aforo, setAforoVal] = useState(null);
  const [caucionPesos, setCaucionPesos] = useState("");
  const [caucionUSD, setCaucionUSD] = useState("");
  const [tcCaucUSD, setTCCaucUSD] = useState("D");

  const [filtro, setFiltro] = useState("");

  const { delAforo, addAforo, getAforos, setAforo } = useServices();

  async function cargaDatos() {
    const listaAforos = await getAforos();
   // console.log({ listaAforos });
    setDatos(listaAforos);
  }

  function selectElementContents(el) {
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand("copy");
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
    }
  }
  async function handleDelAforo(id) {
    //console.log(id);
    const proceed = window.confirm(`Esta seguro de borrar el fondos?`);
    if (!proceed) return;
    const respuesta = await delAforo(id);

    if (!respuesta.error) {
      setDatos(respuesta);
    }
  }

  async function handleAddAforo() {
    if (especie == "") return alert("Especie no definido");
    if (codCajVal == "") return alert("Denominacion no definido");
    if (!aforo) return alert("Aforo no definido");

    const postData = { especie: especie.trim(), codCajVal: codCajVal.trim(), caucionPesos: caucionPesos.trim(), caucionUSD: caucionUSD.trim(), aforo: aforo*1, tcCaucUSD: tcCaucUSD };
    //console.log(postData)
    const proceed = window.confirm("Estas por agregar un aforo, confirmas?");
    if (!proceed) return;

    const respuesta = await addAforo(postData);

    if (!respuesta.error) {
      setDatos(respuesta);
    }
  }
  async function handleSetAforo(Fondo) {
    Fondo.editable = false;
    //console.log(Fondo)
    const proceed = window.confirm("Esta seguro de sobreescribir los datos?");
    if (!proceed) return;
    const respuesta = await setAforo(Fondo);
    if (!respuesta.error) {
      setDatos(respuesta);
    }
  }
  function setEditable(id) {
    datos.find((item) => item.id == id).editable = true;
    setDatos([...datos]);
  }
  function unSetEditable(id) {
    datos.find((item) => item.id == id).editable = false;
    setDatos([...datos]);
  }

  useEffect(() => {
    cargaDatos();
  }, []);
  function editDato(valor, columna, id) {
    //   console.log(valor, columna, id)
    let newDatos = [...datos];

    newDatos.find((item) => item.id == id)[columna] = valor;
    setDatos([...newDatos]);
  }
  function handleUploadResult() {
    cargaDatos()
  }

  const diccTCCaucUSD = { D: "Dólar", C: "Cable" };

  return (
    <div className="card">
      <div className="card-header ">
        <div className="row">
          <div className="col">
            <span className="ml-1">
              <b>Fondos</b>
            </span>{" "}
          </div>
          <div className="col">
            <FileUploadButton
              endPoint={`${baseURL}upload/uploadExcelAforos`}
              fileName={"aforos-byma.json"}
              label={"Carga masiva (excel)"}
              callback={handleUploadResult}
            />
          </div>
          <div className="col">
            <button
              type="button"
              name=""
              data-toggle="tooltip"
              data-placement="bottom"
              title="Copiar"
              onClick={() => {
                selectElementContents(document.getElementById("tb_comit"));
              }}
              className="btn btn-secondary float-right ml-1"
            >
              <FontAwesomeIcon icon={faCopy} />
            </button>

            <input
              placeholder="Código Caja Valores"
              onChange={(e) => setFiltro(e.target.value)}
              className="form-control-sm form-control  float-right ml-1"
              type="text"
              style={{ "max-width": "140px" }}
            ></input>
          </div>
        </div>
      </div>

      <div style={{ maxHeight: "95vh", overflowY: "auto" }} className="card-body">
        <table id={"tb_comit"} className="table table-sm table-bordered table-hover ">
          <thead>
            <tr>
              <th scope="col">Especie</th>
              <th scope="col">Código Caja de Valores</th>
              <th scope="col">Aforo</th>
              <th scope="col">Caución Pesos</th>
              <th scope="col">Caución USD</th>
              <th title="Tipo de cambio para Caución USD" scope="col">TC Caución USD</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input onChange={(e) => setEspecie(e.target.value)} className="form-control" type="text" />
              </td>
              <td>
                <input onChange={(e) => setCodCajVal(e.target.value)} className="form-control" type="text" />
              </td>
              <td>
                <input onChange={(e) => setAforoVal(e.target.value)} className="form-control" type="number" />
              </td>
              <td>
                <input onChange={(e) => setCaucionPesos(e.target.value)} className="form-control" type="text" />
              </td>

              <td>
                <input onChange={(e) => setCaucionUSD(e.target.value)} className="form-control" type="text" />
              </td>
              <td>
                <select onChange={(e) => setTCCaucUSD(e.target.value)} className="form-control" type="text">
                  <option value="D">Dólar</option>
                  <option value="C">Cable</option>
                </select>
              </td>
              <td>
                {" "}
                <button onClick={() => handleAddAforo()} className="btn btn-success btn-sm">
                  <b>+</b>
                </button>
              </td>
            </tr>

            {datos
              .filter((linea) => linea.codCajVal.includes(filtro))
              .map((linea) => (
                <tr key={linea.id}>
                  {!linea.editable && (
                    <>
                      <td>{linea.especie}</td>
                      <td>{linea.codCajVal}</td>
                      <td>{linea.aforo}</td>
                      <td>{linea.caucionPesos}</td>
                      <td>{linea.caucionUSD}</td>
                      <td>{diccTCCaucUSD[linea.tcCaucUSD] || ""}</td>
                    </>
                  )}{" "}
                  {linea.editable && (
                    <>
                      <td>
                        {" "}
                        <input
                          onChange={(e) => editDato(e.target.value, "especie", linea.id)}
                          value={linea.especie}
                          className="form-control"
                          type="text"
                        />{" "}
                      </td>
                      <td>
                        {" "}
                        <input
                          onChange={(e) => editDato(e.target.value, "codCajVal", linea.id)}
                          value={linea.codCajVal}
                          className="form-control"
                          type="text"
                        />{" "}
                      </td>
                      <td>
                        {" "}
                        <input
                          onChange={(e) => editDato(e.target.value, "aforo", linea.id)}
                          value={linea.aforo}
                          className="form-control"
                          type="number"
                        />{" "}
                      </td>
                      <td>
                        {" "}
                        <input
                          onChange={(e) => editDato(e.target.value, "caucionPesos", linea.id)}
                          value={linea.caucionPesos}
                          className="form-control"
                          type="text"
                        />{" "}
                      </td>
                      <td>
                        {" "}
                        <input
                          onChange={(e) => editDato(e.target.value, "caucionUSD", linea.id)}
                          value={linea.caucionUSD}
                          className="form-control"
                          type="text"
                        />{" "}
                      </td>
                      <td>
                        <select value={linea.tcCaucUSD} onChange={(e) => editDato(e.target.value, "tcCaucUSD", linea.id)} className="form-control" type="text">
                          <option value="D">Dólar</option>
                          <option value="C">Cable</option>
                        </select>
                      </td>
                    </>
                  )}
                  <td>
                    <button
                      onClick={() => {
                        handleDelAforo(linea.id);
                      }}
                      className="btn btn-danger btn-sm"
                      title="Eliminar registro"
                    >
                      <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                    </button>
                  </td>
                  {!linea.editable && (
                    <td>
                      <button
                        onClick={() => {
                          setEditable(linea.id);
                        }}
                        className="btn btn-warning btn-sm"
                        title="Editar registro"
                      >
                        <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                      </button>
                    </td>
                  )}
                  {linea.editable && (
                    <td>
                      <button
                        onClick={() => {
                          handleSetAforo(linea);
                        }}
                        className="btn btn-success btn-sm"
                        title="Guardar cambios"
                      >
                        <FontAwesomeIcon icon={faSave}></FontAwesomeIcon>
                      </button>
                      <button
                        onClick={() => {
                          unSetEditable(linea.id);
                        }}
                        className="btn btn-danger btn-sm"
                        title="Cancelar"
                      >
                        <FontAwesomeIcon icon={faBan}></FontAwesomeIcon>
                      </button>
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TablaAforosBYMA;
