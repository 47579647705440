import React, { useEffect, useState } from "react";
import useDocumentTitle from "../hooks/useDocumentTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync, faEdit, faSave, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import useServices from "../hooks/useServices";
const DROP_COPY_FIX = process.env.REACT_APP_DROP_COPY_FIX === "true";
const SALDOS_CONSOLIDADOS_BANCOS = process.env.REACT_APP_MENU_SALDOS_CONSOLIDADOS_BANCOS === "true";

const SaldosConsolidados = () => {
  useDocumentTitle("Saldos Consolidados");
  const {getSaldosConsolidados, saveSaldosPersistencia} = useServices();
  const [isLoading, setIsLoading] = useState(false);
  const [showDetalle, setShowDetalle] = useState(false);
  const [showDetalleBancos, setShowDetalleBancos] = useState(false);
  const [data, setData] = useState({});
  const [saldoBancoCable, setSaldoBancoCable] = useState(0);
  const [saldoBancoPesos, setSaldoBancoPesos] = useState(0);
  const [saldoBancoDolar, setSaldoBancoDolar] = useState(0);
  const [editando, setEditando] = useState(false);
  const [dropCopyFIX, setDropCopyFIX] = useState(false);

  async function handleSaveSaldo() {
     const res = await saveSaldosPersistencia({id: "bancos", saldos:{cable: Number(saldoBancoCable), pesos: Number(saldoBancoPesos), dolar: Number(saldoBancoDolar)}})
     if(res.error) return;
     setEditando(false)
  }
  async function handleDropCopyFIX() {
    setDropCopyFIX((prev) => !prev)
  }


  async function cargaSaldosConsolidados() {
    setIsLoading(true);
    const res = await getSaldosConsolidados(dropCopyFIX);
    if(res.error) return;
    setData(res);
    if(res?.bancos?.C) {
      setSaldoBancoCable(res.bancos?.C);
    }
    if(res?.bancos?.P) {
      setSaldoBancoPesos(res.bancos?.P);
    }
    if(res?.bancos?.D) {
      setSaldoBancoDolar(res.bancos?.D);
    }
    
    
    setIsLoading(false);
  }
  function formatNumero(numero) {
    if (!numero) return "";
    return (numero * 1).toLocaleString("de-DE", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  function handleShowDetail() {
    setShowDetalle((prev) => !prev);
   
  }
  useEffect(() => {
    cargaSaldosConsolidados();
  }, [dropCopyFIX]);

   
  function handleShowDetailBancos() {
    setShowDetalleBancos((prev) => !prev);
   
  }
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header ">
              <div className="row">
                <div className="col">
                  {" "}
                  <b>Saldos Consolidados</b>
                </div>
                <div className="mr-2 " style={{ display: "flex", alignItems: "end" }}>
                {DROP_COPY_FIX && <span className="float-right input-group-text form-control-sm mr-2">
                    Drop Copy FIX
                    <input type="checkbox" value={dropCopyFIX} className="ml-1" onChange={handleDropCopyFIX} />
                  </span>}
                  <span className="float-right input-group-text form-control-sm mr-2">
                    Mostrar detalle
                    <input type="checkbox" value={showDetalle} className="ml-1" onChange={handleShowDetail} />
                  </span>
                  {isLoading ? (
                    <span className="btn btn-sm btn-secondary">Cargando...</span>
                  ) : (
                    <button className="btn btn-sm btn-secondary" onClick={cargaSaldosConsolidados}>
                      {" "}
                      <FontAwesomeIcon icon={faSync} />
                    </button>
                  )}{" "}
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
              <div className="col-12">
                
                <div class="table-responsive">
                  <table className="table table-sm table-bordered table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Mercado</th>
                        <th scope="col" className="text-center" colSpan={3}>
                          BYMA
                        </th>
                        <th scope="col" className="text-center" colSpan={2}>
                          MAV
                        </th>
                        <th scope="col" className="text-center" colSpan={2}>
                          MTR
                        </th>
                      </tr>
                      <tr>
                        <th scope="col">Moneda</th>
                        <th scope="col" className="text-center">
                          ARS
                        </th>
                        <th scope="col" className="text-center">
                          USD
                        </th>
                        <th scope="col" className="text-center">
                          CABLE
                        </th>
                        <th scope="col" className="text-center">
                          ARS
                        </th>
                        <th scope="col" className="text-center">
                          USD
                        </th>
                        <th scope="col" className="text-center">
                          ARS
                        </th>
                        <th scope="col" className="text-center">
                          USD
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="col">Saldo Inicio</th>
                        <td className="text-right"> {formatNumero(data?.byma?.P?.saldoInicio)}</td>
                        <td className="text-right"> {formatNumero(data?.byma?.D?.saldoInicio)}</td>
                        <td className="text-right"> {formatNumero(data?.byma?.C?.saldoInicio)}</td>
                        <td className="text-right"> {formatNumero(data?.mavSaldos?.P?.saldoInicio)}</td>
                        <td className="text-right"> {formatNumero(data?.mavSaldos?.D?.saldoInicio)}</td>
                        <td className="text-right"> {formatNumero(data?.mtrSaldos?.P?.saldoInicio)}</td>
                        <td className="text-right"> {formatNumero(data?.mtrSaldos?.D?.saldoInicio)}</td>
                      </tr>
                      {showDetalle && (
                        <tr>
                          <td>
                            {" "}
                            <b> Detalle Movimientos </b>{" "}
                          </td>
                          <td>
                            <table className="table table-sm table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th scope="col">Concepto</th>
                                  <th scope="col" className="text-right">
                                    Monto
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {data?.byma?.P?.saldosDant?.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.concepto}</td>
                                    <td className="text-right">{formatNumero(item.monto)}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </td>
                          <td>
                            <table className="table table-sm table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th scope="col">Concepto</th>
                                  <th scope="col" className="text-right">
                                    Monto
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {data?.byma?.D?.saldosDant?.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.concepto}</td>
                                    <td className="text-right">{formatNumero(item.monto)}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </td>
                          <td>
                            <table className="table table-sm table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th scope="col">Concepto</th>
                                  <th scope="col" className="text-right">
                                    Monto
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {data?.byma?.C?.saldosDant?.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.concepto}</td>
                                    <td className="text-right">{formatNumero(item.monto)}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </td>
                          <td>
                            <table className="table table-sm table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th scope="col">Concepto</th>
                                  <th scope="col" className="text-right">
                                    Monto
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {data?.mavSaldos?.P?.movDiasAnt?.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.concepto}</td>
                                    <td className="text-right">{formatNumero(item.saldo)}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </td>
                          <td>
                            <table className="table table-sm table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th scope="col">Concepto</th>
                                  <th scope="col" className="text-right">
                                    Monto
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {data?.mavSaldos?.D?.movDiasAnt?.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.concepto}</td>
                                    <td className="text-right">{formatNumero(item.saldo)}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </td>

                          <td></td>
                          <td></td>
                        </tr>
                      )}
                      <tr>
                        <th scope="col">Saldo Antes CI</th>
                        <td className="text-right"> {formatNumero(data?.byma?.P?.saldosAntesCI)}</td>
                        <td className="text-right"> {formatNumero(data?.byma?.D?.saldosAntesCI)}</td>
                        <td className="text-right"> {formatNumero(data?.byma?.C?.saldosAntesCI)}</td>
                        <td className="text-right"> {formatNumero(data?.mavSaldos?.P?.saldoAntsCI)}</td>
                        <td className="text-right"> {formatNumero(data?.mavSaldos?.D?.saldoAntsCI)}</td>
                        <td className="text-right"> {formatNumero(data?.mtrSaldos?.P?.saldosAntesCI)}</td>
                        <td className="text-right"> {formatNumero(data?.mtrSaldos?.D?.saldosAntesCI)}</td>
                      </tr>

                      {showDetalle && (
                        <tr>
                          <td>
                            {" "}
                            <b> Detalle Movimientos </b>{" "}
                          </td>
                          <td>
                            <table className="table table-sm table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th scope="col">Concepto</th>
                                  <th scope="col" className="text-right">
                                    Monto
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {data?.byma?.P?.movHoy?.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.concepto}</td>
                                    <td className="text-right">{formatNumero(item.monto)}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </td>
                          <td>
                            <table className="table table-sm table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th scope="col">Concepto</th>
                                  <th scope="col" className="text-right">
                                    Monto
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {data?.byma?.D?.movHoy?.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.concepto}</td>
                                    <td className="text-right">{formatNumero(item.monto)}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </td>

                          <td>
                            <table className="table table-sm table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th scope="col">Concepto</th>
                                  <th scope="col" className="text-right">
                                    Monto
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {data?.byma?.C?.movHoy?.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.concepto}</td>
                                    <td className="text-right">{formatNumero(item.monto)}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </td>

                          <td>
                            <table className="table table-sm table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th scope="col">Concepto</th>
                                  <th scope="col" className="text-right">
                                    Monto
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {data?.mavSaldos?.P?.movHoy?.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.concepto}</td>
                                    <td className="text-right">{formatNumero(item.saldo)}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </td>
                          <td>
                            <table className="table table-sm table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th scope="col">Concepto</th>
                                  <th scope="col" className="text-right">
                                    Monto
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {data?.mavSaldos?.D?.movHoy?.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.concepto}</td>
                                    <td className="text-right">{formatNumero(item.saldo)}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                      )}
                      <tr>
                        <th scope="col">Saldo Final</th>
                        <td className="text-right"> {formatNumero(data?.byma?.P?.saldoFinal)}</td>
                        <td className="text-right"> {formatNumero(data?.byma?.D?.saldoFinal)}</td>
                        <td className="text-right"> {formatNumero(data?.byma?.C?.saldoFinal)}</td>
                        <td className="text-right"> {formatNumero(data?.mavSaldos?.P?.saldoFinal)}</td>
                        <td className="text-right"> {formatNumero(data?.mavSaldos?.D?.saldoFinal)}</td>
                        <td className="text-right"> {formatNumero(data?.mtrSaldos?.P?.saldoFinal)}</td>
                        <td className="text-right"> {formatNumero(data?.mtrSaldos?.D?.saldoFinal)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                </div>
                </div>
                <div className="row justify-content-center">
                <div className="col-10">
                  <table className="table table-sm table-bordered table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Moneda</th>
                        <th scope="col" className="text-center">
                          ARS
                        </th>
                        <th scope="col" className="text-center">
                          USD
                        </th>
                        <th scope="col" className="text-center">
                          CABLE
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <th scope="col">Total Mercados</th>
                        <td className="text-right">{formatNumero(data?.totalMercados?.P)} </td>
                        <td className="text-right">{formatNumero(data?.totalMercados?.D)} </td>
                        <td className="text-right">{formatNumero(data?.totalMercados?.C)} </td>
                      </tr>
                      <tr>
                        <th scope="col">Total Bancos
                          {data?.bancos?.detalleBancos && <button className="btn btn-sm btn-secondary float-right" onClick={handleShowDetailBancos}>  
                         { showDetalleBancos ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                          </button>}
                        </th>
                        {SALDOS_CONSOLIDADOS_BANCOS ? <> 

                          <td className="text-right">
                         
                         <div class="input-group">
  
                 
                      
                             {!editando ? (
                              <span className="form-control form-control-sm mr-1"> {formatNumero(saldoBancoPesos)} </span>
                             ) : (
                               <input
                                 type="number"
                                 value={saldoBancoPesos}
                                 onChange={(e) => setSaldoBancoPesos(e.target.value * 1)}
                                 className="form-control form-control-sm mr-1"
                                 placeholder="Banco Pesos"
                                 style={{ width: "100px" }}
                               />
                             )}
                   
                             {!editando ? (
                               <button className="btn btn-sm btn-secondary" onClick={() => setEditando(true)}>
                                 <FontAwesomeIcon icon={faEdit} />
                               </button>
                             ) : (
                               <button className="btn btn-sm btn-secondary" onClick={handleSaveSaldo}>
                                 <FontAwesomeIcon icon={faSave} />
                               </button>
                             )}
 
                             </div>
           
                  
                         </td>

                         <td className="text-right">
                         
                         <div class="input-group">
  
                 
                      
                             {!editando ? (
                              <span className="form-control form-control-sm mr-1"> {formatNumero(saldoBancoDolar)} </span>
                             ) : (
                               <input
                                 type="number"
                                 value={saldoBancoDolar}
                                 onChange={(e) => setSaldoBancoDolar(e.target.value * 1)}
                                 className="form-control form-control-sm mr-1"
                                 placeholder="Banco Dolar"
                                 style={{ width: "100px" }}
                               />
                             )}
                   
                             {!editando ? (
                               <button className="btn btn-sm btn-secondary" onClick={() => setEditando(true)}>
                                 <FontAwesomeIcon icon={faEdit} />
                               </button>
                             ) : (
                               <button className="btn btn-sm btn-secondary" onClick={handleSaveSaldo}>
                                 <FontAwesomeIcon icon={faSave} />
                               </button>
                             )}
 
                             </div>
           
                  
                         </td>


                        </> 
                          :
                         <>
                        <td className="text-right">{formatNumero(data?.bancos?.P)} </td>
                        <td className="text-right">{formatNumero(data?.bancos?.D)} </td>
                        </>

                      }
                        <td className="text-right">
                         
                        <div class="input-group">
 
                
                     
                            {!editando ? (
                             <span className="form-control form-control-sm mr-1"> {formatNumero(saldoBancoCable)} </span>
                            ) : (
                              <input
                                type="number"
                                value={saldoBancoCable}
                                onChange={(e) => setSaldoBancoCable(e.target.value * 1)}
                                className="form-control form-control-sm mr-1"
                                placeholder="Banco Cable"
                                style={{ width: "100px" }}
                              />
                            )}
                  
                            {!editando ? (
                              <button className="btn btn-sm btn-secondary" onClick={() => setEditando(true)}>
                                <FontAwesomeIcon icon={faEdit} />
                              </button>
                            ) : (
                              <button className="btn btn-sm btn-secondary" onClick={handleSaveSaldo}>
                                 <FontAwesomeIcon icon={faSave} />
                              </button>
                            )}

                            </div>
          
                 
                        </td>
                      </tr>
                      {showDetalleBancos && (
                        <tr>
                          <td>
                            {" "}
                            <b> Detalle Bancos </b>
                          </td>
                          <td>
                            <table className="table table-sm table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th scope="col">Cuenta</th>
                                  <th scope="col" className="text-right">
                                    Monto
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {data?.bancos?.detalleBancos?.P.map((item) => (
                                  <tr key={item.account}>
                                    <td>{item.account}</td>
                                    <td className="text-right">{formatNumero(item.saldo)}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </td>
                          <td>
                            <table className="table table-sm table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th scope="col">Cuenta</th>
                                  <th scope="col" className="text-right">
                                    Monto
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {data?.bancos?.detalleBancos?.D.map((item) => (
                                  <tr key={item.account}>
                                    <td>{item.account}</td>
                                    <td className="text-right">{formatNumero(item.saldo)}</td>
                                  </tr>
                                )) }
                              </tbody>
                            </table>
                          </td>
                          <td></td>
                        </tr>
                      )}
                      <tr>
                        <th scope="col">Consolidado</th>
                        <td className="text-right">
                          <b>{formatNumero(data?.saldosConsolidados?.P - data?.bancos?.P + saldoBancoPesos)} </b>{" "}
                        </td>
                        <td className="text-right">
                          <b>{formatNumero(data?.saldosConsolidados?.D - data?.bancos?.D + saldoBancoDolar)} </b>{" "}
                        </td>
                        <td className="text-right">
                          <b>{formatNumero(data?.saldosConsolidados?.C - data?.bancos?.C + saldoBancoCable)} </b>{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                
                </div>
                </div>
        
            </div>
          </div>
        </div>
      </div>
  

    );

  }

export default SaldosConsolidados;
