import React from 'react'
import useDocumentTitle from '../hooks/useDocumentTitle'
import ConciliaBancosComponent from '../components/ConciliaBancosComponent'


const ConciliacionBancos = () => {
    useDocumentTitle("Conciliacion Bancos")
  return (
    <div className="container-fluid"> 
    <div className="row">
        <div className="col-12">
            <ConciliaBancosComponent />
        </div>

    </div>
    
    </div>
  )
}

export default ConciliacionBancos